/* eslint-disable*/
import axios from '@axios'
import { utf8ToB64 } from "@/store/functions";
import * as constants from "@core/utils/constants";
// import generateInputs from '@/libs/utils/generate-inputs'
import { getUserData } from "@/auth/utils";
/* eslint-disable no-unused-vars */
export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchResources(ctx, queryParams) {
      const userData = getUserData();
      let defaultLang = 'es'
      try {
        defaultLang = getUserData().profile.client.defaultLanguage
      } catch (error) {
        console.log(error)
      }
  
      let headers = {
        'Accept-Language': defaultLang,
        'Content-Language': defaultLang,
      }
      let isSponsor = false
      if (userData.groups.edges.length != 0) {
        isSponsor = userData.groups.edges[0].node.name == constants.sponsor ? true : false
      }
      return new Promise((resolve, reject) => {

        axios
          .post('', {
            query: `
              {
                allResources(
                  first: ${queryParams.perPage},
                  offset: ${queryParams.perPage * (queryParams.page - 1)},
                  name: "${queryParams.q}",client:"${userData.profile.client.id}",
                  ${isSponsor ? `creator:"${utf8ToB64(`id:${userData.id}`)}",` : ``}
                  ${queryParams.t == 'null' ? '' : `type: "${queryParams.t}",`}
                  orderBy: "${queryParams.sortDesc ? '-' : ''}${queryParams.sortBy}",
                  ${queryParams.status !== null ? 'isActive: ' : ''}${queryParams.status !== null ? queryParams.status.toLowerCase() : ''}
                ) {
                  totalCount
                  edgeCount
                  edges {
                    node {
                      id
                      name
                      description
                      modifiedAt
                      type
                      test {
                        id
                        name
                        testAnswer{
                          id
                          response
                          order
                        }
                      }
                     
                    }
                  }
                }
              }
            `,
          },{headers})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchResource(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    mutateResource(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteResource(ctx, { id, type, test, file }) {
      if (type === 'TST' && test) {
        const listAnswers = test.map(item => item.id)
        const variablesAnswers = {
          list: listAnswers,
        }
        const queryAnswers = `
          mutation($list: [ID]!){
            batchDeleteAnswers(ids:$list){
              deletedIds
            }
          }
        `
        axios.post('', { query: queryAnswers, variables: variablesAnswers })
      }
      return new Promise((resolve, reject) => {
        const variables = {
          id,
        }
        const query = `
          mutation($id: ID!){
            deleteResource(id:$id) {
              found
              deletedId
            }
          }`
        axios
          .post('', {
            query,
            variables,
          })
          .then(response => {
            if (file) {
              axios
                .post('', {
                  query: `mutation{
                  deleteFile(id:"${file}") {
                    found
                    deletedId
                  }
                }`,
                }).then(response => resolve(response.data.data.deleteFile))
                .catch(error => reject(error))
            } else {
              resolve(response.data.data.deleteResource)
            }
          })
          // 
          .catch(error => reject(error))
      })
    },
    addResource(ctx, data) {

      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}